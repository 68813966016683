import { PropsWithChildren } from 'react';
import { useRedirect } from 'react-admin';
import { Lock } from '@mui/icons-material';
import { clsx } from 'clsx';

import cls from './initial-card.module.css';
import { LightTooltip } from '@components/LightTooltip';
import { InfoTooltip } from '@components/InfoTooltip';

interface Props {
  left?: string | React.ReactNode | false;
  leftTooltip?: string;
  center?: string | React.ReactNode | false;
  right?: string | React.ReactNode | false;
  link?: string;
  boxClass?: string;
  wrapperClass?: string;
  contentClass?: string;
  autoHeight?: boolean;
  mt?: number;
  mb?: number;
  fullHeight?: boolean;
  extraPadding?: boolean;
  mediumPadding?: boolean;
  riskPadding?: boolean;
  lockText?: string | boolean | null | undefined;
  minHeight?: number;
}

export const InitialCard = ({
  children, left, leftTooltip, center, right, link, boxClass,
  wrapperClass, contentClass, autoHeight, mt, mb, fullHeight,
  extraPadding, mediumPadding, riskPadding, lockText, minHeight,
}: PropsWithChildren<Props>) => {
  const showHeader = left || right || center;
  const redirect = useRedirect();

  const onClick = () => link && redirect(link);

  return (
    <div onClick={onClick} className={clsx(
      wrapperClass,
      'InitialCard',
      cls.card,
      boxClass,
      autoHeight && cls.autoHeight,
      link && cls.cardWithLink,
      !showHeader && cls.cardWithoutHeader,
      extraPadding && cls.extraPadding,
      mediumPadding && cls.mediumPadding,
      riskPadding && cls.riskPadding,
      fullHeight && cls.fullHeight,
    )} style={{ marginTop: mt, marginBottom: mb, minHeight }}>
      {showHeader ? (
        <div className={clsx(
          cls.cardHeader,
          !left && !right && center && cls.center,
        )}>
          {left && (
            <div className={clsx('InitialCard-left', cls.cardHeaderLeft)}>
              {left}{leftTooltip && <InfoTooltip className={cls.tooltip} title={leftTooltip} />}
            </div>
          )}
          {center && (
            <div className="InitialCard-center">{center}</div>
          )}
          {right && (
            <div className="InitialCard-right">{right}</div>
          )}
          {lockText && (
            <LightTooltip extraWidth title={lockText}>
              <Lock className={cls.lockIcon} />
            </LightTooltip>
          )}
        </div>
      ) : null}
      <div className={contentClass}>
        {children}
      </div>
    </div>
  );
};
